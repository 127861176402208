import logo from './logo.png';
import video from './backgroundVideo.mp4'
import './App.css'
import Video from './Video.js'
function App() {
  
  const handleMobile =()  => {
    if (window.innerWidth > 720) {
      return <Video />
  } 
  }
  
  return (
   
   <div style={{}}>
    
{    handleMobile()}
    
     {/* <video style={{ 
    position:"fixed", 
    top:0,
    left:0,
    height:"100vh",
    opacity: 1,
    width:"100vw",
    zIndex: -1,
    backgroundSize:"cover"
          }} autoPlay loop muted>
        <source
          src={video}
          type="video/mp4"
        />
      </video> */}
        
       
      {/* <div style={{alignItems:"center", justifyContent:"center", width:"100vw", backgroundColor:"rgba(10,10,10,0.8", height:"15vh"}}>
          <h1 style={{textAlign:"center", justifyContent:"center", color:"white", padding:20, fontFamily:"Hurricane"}}> ÇAĞRIBEY <br/>HARİTA </h1>
      </div> */}

      <div style={{alignItems:"center", justifyContent:"center", display:"flex"}}>
        <img style={{maxWidth:"40vw"}} src={logo}>
        </img>
      </div>
      <div style={{maxWidth:900, backgroundColor:"rgba(33,42,66,0.90", borderRadius:5, alignItems:"center", displayContent:"center", display:"flex", margin:"auto", marginTop:"10vh"}}>

</div>
     
      <div style={{maxWidth:900, backgroundColor:"rgba(33,42,66,0.75", color:"#d1d1d1", borderRadius:5, alignItems:"center", displayContent:"center", margin:"auto"}}>
      <h1 style={{width:"100%", margin:"auto", textAlign:"center", color:"white", fontFamily:"Hurricane"}}>HAKKIMIZDA</h1>
      <hr/>
        
        <p style={{textAlign:"center"}}>Hizmete Aralık 2011'de başlayan firmamız Amasya ili ve çevre bölgede;  
        <ul style={{fontSize:"0.8rem", textAlign:"left"}}>
        <li>Etüt, Proje ve Mühendislik Hizmetleri</li>
  <li>İmar Planı ve Uygulaması</li>
  <li>Kamulaştırma Planları</li>
  <li>Sayısal Halihazır Yapım İşleri</li>
  <li>Maden Ocağı Harita Hizmetleri</li>
  <li>Birleştirme Ayırma ve Yola Terk İşlemleri</li>
  <li>Bina Aplikasyon İşlemleri</li>
  <li>Milli Emlak Arazi Satış ve Kiralama Danışmanlığı</li>
  <li>Kübaj İşlemleri</li>
  <li>Danışmanlık Hizmetleri</li> 
        </ul>
        alanlarında hizmet vermektedir. <br /> 
  Sektörel tecrübemiz firmanın kısa zamanda yükselmesine ve nihayetinde sektöründe öncü konuma gelmesine yardımcı olmuştur.<br/>
        </p>
      </div>

    </div>
  );
}

export default App;
