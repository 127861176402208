import React from 'react'
import video from './backgroundVideo.mp4'

function Video() {
  return (
    
         <video style={{ 
    position:"fixed", 
    top:0,
    left:0,
    height:"auto",
    opacity: 1,
    width:"100vw",
    zIndex: -1,
    backgroundSize:"cover"
          }} autoPlay loop muted>
        <source
          src={video}
          type="video/mp4"
        />
      </video>
    
  )
}

export default Video